<template>
  <div>
    <div class="card" id="kt_modal_create_edit_site" ref="createEditSiteRef" tabindex="-1" aria-hidden="true" key='state.rerender'>
      <div class="">
        <div class="modal-content" style='background: none;'>
          <div class="modal-header">
            <h2>{{ state.titleModal }}</h2> <!-- {{ state.formData }} -->
            <div class="btn btn-sm btn-active-color-danger ">
              <span class="svg-icon svg-icon-1">
                <router-link to="/stilldeploy/sites" active-class="active" >
                  <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
                  <span class="text-gray">Retour à la gestion des sites</span>
                </router-link>
                
              </span>
            </div>
          </div>
          <div class="modal-body scroll-y m-5">
            <div class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_account_stepper" ref="createAccountRef">
              <div class="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
                <div class="stepper-nav ps-lg-10" style="padding-left: 0 !important;   box-shadow: 6px 0px 7px -8px rgb(0 0 0 / 68%);padding-top: 20px;">
                  <div class="stepper-item current" data-kt-stepper-element="nav">
                    <div class="stepper-line w-40px"></div>
                    <div class="stepper-icon w-40px h-40px">
                      <i class="stepper-check fas fa-check"></i>
                      <span class="stepper-number">1</span>
                    </div>
                    <div class="stepper-label">
                      <h3 class="stepper-title">Informations</h3>
                      <div class="stepper-desc">Généralités sur le site</div>
                    </div>
                  </div>
                  <div class="stepper-item" data-kt-stepper-element="nav">
                    <div class="stepper-line w-40px"></div>
                    <div class="stepper-icon w-40px h-40px">
                      <i class="stepper-check fas fa-check"></i>
                      <span class="stepper-number">2</span>
                    </div>
                    <div class="stepper-label">
                      <h3 class="stepper-title">Adresse détaillée</h3>
                      <div class="stepper-desc">Où se trouve le site ?</div>
                    </div>
                  </div>
                  <div class="stepper-item" data-kt-stepper-element="nav">
                    <div class="stepper-line w-40px"></div>
                    <div class="stepper-icon w-40px h-40px">
                      <i class="stepper-check fas fa-check"></i>
                      <span class="stepper-number">3</span>
                    </div>
                    <div class="stepper-label">
                      <h3 class="stepper-title">Contacts</h3>
                      <div class="stepper-desc">
                        Sélection des contacts du site
                      </div>
                    </div>
                  </div>
                  <div class="stepper-item" data-kt-stepper-element="nav">
                    <div class="stepper-line w-40px"></div>
                    <div class="stepper-icon w-40px h-40px">
                      <i class="stepper-check fas fa-check"></i>
                      <span class="stepper-number">4</span>
                    </div>
                    <div class="stepper-label">
                      <h3 class="stepper-title">{{ state.subTitle }}</h3>
                      <div class="stepper-desc">
                        Et quelques informations facultatives...
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-row-fluid py-lg-0 px-lg-15">
                <form class="form" novalidate="novalidate" id="kt_create_account_form" @submit="handleStep">
                  <div class="current" data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="pb-10 pb-lg-10">
                        <h2 class="fw-bolder d-flex align-items-center text-dark">
                          Informations principales du site
                        </h2>
                      </div>
                      <div class="mb-10 fv-row">
                        <label class="form-label mb-3 required">Libellé du site</label>
                        <Field type="text" class="form-control form-control-lg form-control-solid" name="siteLabel" v-model="state.formData.siteLabel" placeholder="Comment souhaitez-vous nommer ce site ?" />
                        <ErrorMessage class="fv-plugins-message-container invalid-feedback" name="siteLabel" />
                      </div>
                      <div class="d-flex flex-stack mb-10">
                        <div class="me-5">
                          <label class="fs-6 fw-bold form-label">Afficher ce site sur la carte ?</label>
                          <div class="fs-7 fw-bold text-gray-400">
                            Si décoché, le site sera tout de même affiché dans le listing des sites.
                          </div>
                        </div>
                        <label class="form-check form-switch form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox" v-model="state.formData.siteAffMap" />
                        </label>
                      </div>
                      <div class="mb-0 fv-row">
                        <label class="d-flex align-items-center form-label mb-5">
                          Sélectionnez le type de site
                        </label>
                        <div class="mb-5">
                          <label class="d-flex flex-stack mb-5 cursor-pointer">
                            <span class="d-flex align-items-center me-2">
                              <span class="symbol symbol-50px me-6">
                                <span class="symbol-label">
                                  <span class="svg-icon svg-icon-1 svg-icon-primary">
                                    <inline-svg src="media/icons/duotune/maps/map001.svg" />
                                  </span>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fw-bolder text-gray-800 text-hover-primary fs-5">Site de livraison</span>
                                <span class="fs-6 fw-bold text-gray-400">Livraison d'équipement</span>
                              </span>
                            </span>
                            <span class="form-check form-check-custom form-check-solid">
                              <input class="form-check-input" type="radio" checked name="siteType" value=1 v-model="state.formData.siteType" />
                            </span>
                          </label>
                          <label class="d-flex flex-stack mb-5 cursor-pointer">
                            <span class="d-flex align-items-center me-2">
                              <span class="symbol symbol-50px me-6">
                                <span class="symbol-label">
                                  <span class="svg-icon svg-icon-1 svg-icon-success">
                                    <inline-svg src="media/icons/duotune/finance/fin001.svg" />
                                  </span>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fw-bolder text-gray-800 text-hover-primary fs-5">Siège sociale / Centre d'affaires</span>
                                <span class="fs-6 fw-bold text-gray-400">Adresse administrative</span>
                              </span>
                            </span>
                            <span class="form-check form-check-custom form-check-solid">
                              <input class="form-check-input" type="radio" name="siteType" value=2 v-model="state.formData.siteType" />
                            </span>
                          </label>
                          <label class="d-flex flex-stack mb-5 cursor-pointer">
                            <span class="d-flex align-items-center me-2">
                              <span class="symbol symbol-50px me-6">
                                <span class="symbol-label">
                                  <span class="svg-icon svg-icon-1 svg-icon-info">
                                    <inline-svg src="media/icons/duotune/technology/teh008.svg" />
                                  </span>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fw-bolder text-gray-800 text-hover-primary fs-5">Site de stockage</span>
                                <span class="fs-6 fw-bold text-gray-400">Centre logistique / Emplacement stratégique</span>
                              </span>
                            </span>
                            <span class="form-check form-check-custom form-check-solid">
                              <input class="form-check-input" type="radio" name="siteType" value=3 v-model="state.formData.siteType" />
                            </span>
                          </label>
                          <label class="d-flex flex-stack mb-0 cursor-pointer">
                            <span class="d-flex align-items-center me-2">
                              <span class="symbol symbol-50px me-6">
                                <span class="symbol-label">
                                  <span class="svg-icon svg-icon-1 svg-icon-danger">
                                    <inline-svg src="media/icons/duotune/maps/map007.svg" />
                                  </span>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <Field type="text" class="form-control form-control-lg form-control-solid" name="siteTypeCustom" placeholder="Type spécifique" v-model="state.formData.siteTypeCustom"/>
                                <ErrorMessage class="fv-plugins-message-container invalid-feedback" name="siteTypeCustom" />
                              </span>
                            </span>
                            <span class="form-check form-check-custom form-check-solid">
                              <input class="form-check-input" type="radio" name="siteType" value=4 v-model="state.formData.siteType" />
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="pb-10 pb-lg-12">
                        <h2 class="fw-bolder text-dark">Adresse détaillée</h2>
                        <div class="text-gray-400 fw-bold fs-6">
                          Localisation exacte de votre site.
                        </div>
                      </div>
                      <div class="fv-row mb-10">
                        <label class="form-label required">Adresse (Numéro et rue)</label>
                        <Field name="adr_ligne_1" class="form-control form-control-lg form-control-solid" v-model="state.formData.adr_ligne_1" />
                        <ErrorMessage class="fv-plugins-message-container invalid-feedback" name="adr_ligne_1" />
                      </div>
                      <div class="fv-row mb-10">
                        <label class="d-flex align-items-center form-label">
                          <span>Complément</span>
                          <span style="font-size: 0.775em;">
                            &nbsp;(Précision sur le batiment, la porte spécifique, l'accès...)
                          </span>
                        </label>
                        <Field name="adr_ligne_2" class="form-control form-control-lg form-control-solid" v-model="state.formData.adr_ligne_2"/>
                        <ErrorMessage class="fv-plugins-message-container invalid-feedback" name="adr_ligne_2" />
                      </div>
                      <div class="fv-row mb-10">
                        <label class="d-flex align-items-center form-label required">
                          <span>Code postal / Ville / Pays</span>
                        </label>
                        <Field name="adr_cp" autoComplete="postal-code" class="form-control form-control form-control-solid" style="width: 15%; display: inline;" v-model="state.formData.adr_cp" />
                        <Field name="adr_ville" autoComplete="address-level2" class="form-control form-control form-control-solid" style="width: 54%; display: inline; margin-left: 1%;" v-model="state.formData.adr_ville" />
                        <Field name="adr_pays" autoComplete="country-name" class="form-control form-control form-control-solid" style="width: 29%; display: inline; margin-left: 1%;" v-model="state.formData.adr_pays" />
                        <ErrorMessage class="fv-plugins-message-container invalid-feedback" name="adr_ville" />
                      </div>
                      <div class="fv-row mb-10">
                        <label class="form-label required">Géolocalisation</label>
                        <div>
                          <a class="btn btn-sm btn-active-secondary me-2 mb-2 btn-bg-primary btn-text-white" @click="searchGeoLoc(state.formData);">
                            Rechercher les coordonnées de l'adresse du site
                          </a>
                        </div>
                        <Field name="corporationType" class="form-select form-select-lg form-select-solid" as="select" v-model="state.formData.adr_coord" v-if="state.listGeoLoc">
                          <option v-for="item in state.listGeoLoc" :value="item.geometry.coordinates" :key="item.properties.place_id">{{item.properties.formatted}}</option>
                        </Field>
                        <ErrorMessage class="fv-plugins-message-container invalid-feedback" name="corporationType" />
                      </div>
                    </div>
                  </div>
                  <div data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="pb-10 pb-lg-15">
                        <h2 class="fw-bolder text-dark">Contacts</h2>
                        <div class="text-gray-400 fw-bold fs-6">
                          Veuillez sélectionnez les personnes liées au site.
                        </div>
                        <a class="btn btn-sm btn-active-secondary me-2 mt-4 btn-bg-primary btn-text-white" @click="new_contact();" v-if="!state.newContact">
                          Créer un nouveau contact
                        </a>
                      </div>
                      <div class="d-flex flex-column mb-7 fv-row">
                        <div class="table-responsive" style="max-height: 55vh;">
                          <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" >
                            <thead>
                              <tr class="fw-bolder text-muted">
                                <th class="w-25px"></th>
                                <th class="min-w-150px">Contact</th>
                                <th class="min-w-140px">Coordonnées</th>
                              </tr>
                            </thead>
                            <tbody>
                                <template v-if="state.newContact">
                                <tr style='background-color: white !important'>
                                  <td>
                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                      <input class="form-check-input widget-9-check" type="checkbox" />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="d-flex align-items-center">
                                      <div class="d-flex justify-content-start flex-column">
                                          <Field name="new_contact_civilite" class="form-select form-select-lg form-select-solid mt-2" style="width: 100%; display: block; border: 1px solid #dcdfe6; height: 43px" as="select" v-model="state.con_civilite">
                                            <option value='0'>Monsieur</option>
                                            <option value='1'>Madame</option>
                                          </Field>
                                          <Field  name="new_contact_prenom" placeholder="Prénom" v-model="state.con_prenom" class="form-control form-control form-control-solid mt-2" style="width: 100%; display: block;" />
                                          <Field  name="new_contact_nom" placeholder="Nom" v-model="state.con_nom" class="form-control form-control form-control-solid mt-2" style="width: 100%; display: block;" />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <Field  name="new_contact_tel1" v-model="state.con_tel1" placeholder="Télephone Fixe" class="form-control form-control form-control-solid mt-2" />
                                    <Field  name="new_contact_tel2" v-model="state.con_tel2" placeholder="Télephone Mobile" class="form-control form-control form-control-solid mt-2" />
                                    <Field  name="new_contact_mail" v-model="state.con_mail" placeholder="Adresse mail" class="form-control form-control form-control-solid mt-2" />
                                  </td>
                                </tr>
                                <tr style='background-color: white !important; text-align: right;'>
                                  <td colspan=3>
                                    <a class="btn btn-sm btn-active-secondary me-2 mb-2 btn-bg-white btn-text-dark" @click="state.newContact = false">
                                      Annuler
                                    </a>
                                    <a class="btn btn-sm btn-active-secondary me-2 mb-2 btn-bg-primary btn-text-white" @click="saveNewContact()">
                                      Créer le contact
                                    </a>
                                  </td>
                                </tr>
                                </template>
                                <template v-if="!state.newContact">
                                  <template v-for="(item, index) in state.listContacts" :key="index" >
                                    <tr>
                                      <td>
                                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                                          <input class="form-check-input widget-9-check" type="checkbox" :value="item.con_seq" v-model="state.formData.contacts"/>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="d-flex align-items-center">
                                          <div class="d-flex justify-content-start flex-column">
                                            <a class="text-dark fw-bolder text-hover-primary fs-6">{{ item.con_prenom }} {{ item.con_nom }}</a>
                                            <span class="text-muted fw-bold text-muted d-block fs-7">{{ item.con_mail }}</span>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <a :href="'sip://'+item.con_tel1" class="text-dark fw-bolder text-hover-primary d-block fs-7">{{ item.con_tel1 }}</a>
                                        <a :href="'sip://'+item.con_tel2" class="text-dark fw-bolder text-hover-primary d-block fs-7">{{ item.con_tel2 }}</a>
                                      </td>
                                    </tr>
                                  </template>
                                </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="pb-8 pb-lg-10">
                        <h2 class="fw-bolder text-dark">Dernière étape !</h2>
                      </div>
                      <div class="text-gray-400 fw-bold fs-6 mb-10">
                        Si vous le souhaitez, vous pouvez aouter des notes complémentaires concernant ce site.
                      </div>
                      <div class="mb-0">
                        <div class="mb-5">
                          <el-input
                            v-model="state.formData.adr_complement"
                            :autosize="{ minRows: 6, maxRows: 12}"
                            type="textarea"
                            placeholder="Veuillez saisir ici, des notes spécifiques sur le site..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-stack pt-8">
                    <template v-if="!state.newContact">
                      <div class="me-2">
                        <button type="button" class="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous" @click="previousStep()">
                          <span class="svg-icon svg-icon-3 me-1">
                            <inline-svg src="media/icons/duotune/arrows/arr063.svg" />
                          </span>
                          Retour
                        </button>
                      </div>
                      <div>
                        <button type="submit" class="btn btn-lg btn-primary" v-if="currentStepIndex === totalSteps - 1" @click="formSubmit()">
                          <span class="indicator-label">
                            {{ state.subTitle }}
                            <span class="svg-icon svg-icon-3 ms-2 me-0">
                              <inline-svg src="icons/duotune/arrows/arr064.svg" />
                            </span>
                          </span>
                          <span class="indicator-progress">
                            Veuillez patienter...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                        <button type="submit" class="btn btn-lg btn-primary" v-else>
                          Continuer
                          <span class="svg-icon svg-icon-3 ms-1 me-0">
                            <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
                          </span>
                        </button>
                      </div>
                    </template>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { computed, defineComponent, onMounted, ref, reactive} from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import mAxiosApi from "@/api";
import mAxios from "axios";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { useRouter } from "vue-router";

interface Step1 {
  siteNum: any;
  siteLabel: string;
  siteAffMap: boolean;
  siteTypeCustom: string;
  siteType: string;
  contacts: any;
}

interface Step2 {
  adr_ligne_1: string;
  adr_ligne_2: string;
  adr_cp: string;
  adr_ville: string;
  adr_pays: string;
  adr_coord: any;
}

interface Step3 {
  shortenedDescriptor: string;
  corporationType: string;
  businessDescription: string;
  contactEmail: string;
}

interface Step4 {
  adr_complement: string;
  nameOnCard: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cvv: string;
}

interface KTCreateApp extends Step1, Step2, Step3, Step4 {}

export default defineComponent({
  name: "create-edit-site",
  props: {
    mCode: String,
  },
  components: {
    Field,
    ErrorMessage,
  },
  setup(props) {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const createEditSiteRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const router = useRouter();

    const mCode =
          props.mCode && props.mCode.length > 0
            ? props.mCode
            : (router.currentRoute.value.params.code as string);

    const state = reactive({
      idSite: 0,      
      titleModal : 'Ajouter un nouveau site',
      subTitle : 'Création du site',
      rerender : 0,
      listGeoLoc : null,
      listContacts : [],
      newContact : false,
      con_civilite: 0,
      con_prenom: '',
      con_nom: '',
      con_tel1: '',
      con_tel2: '',
      con_mail: '',
      formData : ref<KTCreateApp>({
            siteNum: 0,
            siteLabel: "",
            siteAffMap : false,
            siteTypeCustom: "",
            siteType: '',
            adr_ligne_1: '',
            adr_ligne_2: '',
            adr_cp: '',
            adr_ville: '',
            adr_pays: '',
            adr_complement: '',
            adr_coord: [],
            shortenedDescriptor: "KEENTHEMES",
            corporationType: "1",
            businessDescription: "",
            contactEmail: "corp@support.com",
            nameOnCard: "Max Doe",
            cardNumber: "4111 1111 1111 1111",
            expirationMonth: "1",
            expirationYear: "2022",
            cvv: "123",
            contacts: [],
          }),
    });

    const mAxiosGen = mAxios.create({
      withCredentials: false,
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );
      if(mCode == "0"){
        addSite();
      } else {
        editSite(mCode)
      }

    });

    const createAppSchema = [
      Yup.object({
        siteLabel: Yup.string().required(
          "Le libellé du site est obligatoire pour continuer."
        ),
        siteTypeCustom: Yup.string().when("state.formData.siteType", {
          is: "4",
          then: Yup.string().required(
            "Le libellé du type est obligatoire pour continuer."
          ),
        }),
      }),
      Yup.object({}),
      Yup.object({}),
      Yup.object({}),
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) return;
      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3 | Step4>({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (!_stepperObj.value) return;
      currentStepIndex.value--;
      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit((values) => {
      for (const item in values) {
        
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            if(item == 'contacts') continue;
            if(item == 'adr_coord') continue;
            state.formData[item] = values[item];
          }
        }
      }

      currentStepIndex.value++;
      if (!_stepperObj.value) return;
      
      _stepperObj.value.goNext();
    });

    const searchGeoLoc = async (obj) => {
      const stringAdr = encodeURI("street=" + obj.adr_ligne_1 + " " + obj.adr_ligne_2 + "&postcode=" + obj.adr_cp +  "&city=" + obj.adr_ville + "&country="+obj.adr_pays);
      
      const response = await mAxiosGen.get("https://api.geoapify.com/v1/geocode/search?" + stringAdr + "&limit=5&apiKey=1b48259b810e48ddb151889f9ea58db0");
      if (response.data.features.length > 0) {
        
        state.listGeoLoc = response.data.features;
        state.formData.adr_coord = response.data.features[0].geometry.coordinates;
        //console.log(state.formData.adr_coord);
      }
    }

    const formSubmit = () => {
      const sendData = state.formData;
        mAxiosApi
          .post("addupdateSite", { sendData })
          .then( async () => {
              Swal.fire({
                text: 'Opération réalisée avec succés !',
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Retour à la gestion des sites",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(() => {
                // hideModal(createEditSiteRef.value);
                router.push({  name: "sites" });
              });
          })
          .catch((err) => {
            console.error(err);
          });

    };

    resetForm({
      values: {
        ...state.formData,
      },
    });

    async function addSite() {
      state.idSite = 0;
      state.titleModal = "Ajout d'un nouveau site";
      state.subTitle = "Création du site";
      resetForm();
      state.formData.siteNum = 0;       

      await listContacts();      
      const arrayContacts=[0];
      state.formData.contacts = arrayContacts;     
      state.formData.siteAffMap = true;

      for (let i=0; i<=currentStepIndex.value; i++) if(currentStepIndex.value>0) previousStep();

    }

    async function listContacts() {
      let myList = await getAxios("/getContacts");
      state.listContacts = myList.results;
    }

    function new_contact() {
      state.con_civilite = 0;
      state.con_prenom = "";
      state.con_nom = "";
      state.con_tel1 = "";
      state.con_tel2 = "";
      state.con_mail = "";
      state.newContact = true;
    }

    async function editSite(idSite) {
        state.newContact = false;
        state.idSite = idSite;
        state.titleModal = "Modification d'un site";
        state.subTitle = "Modifier le site";

        let myList = await getAxios("/getSite/" + idSite);
        for (let i=0; i<=currentStepIndex.value; i++) if(currentStepIndex.value>0) previousStep();
        await listContacts();      
        const arrayContacts=[0];
        state.listContacts.forEach(
          function(item: any){
            if(item.con_knum_adr === idSite) {
              arrayContacts.push(item.con_seq);
            }
          }
        );
        state.formData.contacts = arrayContacts;
        state.formData.siteNum = idSite;       

        state.listGeoLoc = null;
        state.formData.adr_coord = [myList.results[0].adr_lng, myList.results[0].adr_lat];
        state.formData.siteLabel = myList.results[0].adr_c_site_label ? myList.results[0].adr_c_site_label : '';
        state.formData.siteAffMap = myList.results[0].adr_x_aff_carte === "1";
        
        switch (myList.results[0].adr_c_site_type) {
          case 'Livraison':
          case '':
          case null:
            state.formData.siteTypeCustom = "";
            state.formData.siteType = "1";
            break;
          case 'Siège social':
            state.formData.siteTypeCustom = "";
            state.formData.siteType = "2";
            break;
          case 'Stockage':
            state.formData.siteTypeCustom = "";
            state.formData.siteType = "3";
            break;
          default:
            state.formData.siteTypeCustom = myList.results[0].adr_c_site_type ? myList.results[0].adr_c_site_type : '';
            state.formData.siteType = "4";
        }

        state.formData.adr_ligne_1 = myList.results[0].adr_ligne_1;
        state.formData.adr_ligne_2 = myList.results[0].adr_ligne_2;
        state.formData.adr_cp = myList.results[0].adr_cp;
        state.formData.adr_ville = myList.results[0].adr_ville;
        state.formData.adr_pays = myList.results[0].adr_pays;
        state.formData.adr_complement = myList.results[0].adr_complement;

    }

    const saveNewContact = async () => {
        mAxiosApi
          .post("addContact", { con_civilite: state.con_civilite, con_prenom: state.con_prenom, con_nom: state.con_nom, con_tel1: state.con_tel1, con_tel2: state.con_tel2, con_mail: state.con_mail, con_knum_adr: state.idSite>0 ? state.idSite : null })
          .then( async () => {
            await listContacts();
            state.newContact = false;
          })
          .catch((err) => {
            console.error(err);
          });
    };


    return {
      createAccountRef,
      totalSteps,
      previousStep,
      handleStep,
      formSubmit,
      currentStepIndex,
      state,
      createEditSiteRef,
      addSite,
      editSite,
      searchGeoLoc,
      listContacts,
      new_contact,
      saveNewContact
    };
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

</script>
